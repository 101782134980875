import { Helmet } from 'react-helmet'
import Preloader from 'shared/kuali-common/components/Preloader'
import React, { lazy, Suspense } from 'react'
import withProps from './common/with-props'

export const createModulePage = (title, componentFactory) =>
  withProps(ModulePage, {
    moduleComponent: lazy(componentFactory),
    title
  })

export const ModulePage = ({ title, moduleComponent: ModuleComponent }) => (
  <>
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={title} />
    </Helmet>
    <Suspense fallback={<Preloader />}>
      <ModuleComponent />
    </Suspense>
  </>
)
