/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

import { defineMessages } from 'react-intl'

export default defineMessages({
  alt: {
    id: 'preloader.alt',
    description: 'Alt text for loading animation',
    defaultMessage: 'A loading animation'
  },
  main: {
    id: 'preloader.main',
    description: 'Main content for loading placeholder',
    defaultMessage: 'Loading, please wait.'
  }
})
