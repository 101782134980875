import React from 'react'

const withProps = (Component, props, { forwardProps = true } = {}) => {
  const ComponentWithProps = componentProps => (
    <Component {...(forwardProps ? componentProps : null)} {...props} />
  )

  ComponentWithProps.displayName = `withProps(${Component.displayName})`

  return ComponentWithProps
}

export default withProps
