import { parse } from 'qs'

const replaceQueryWithHash = (location, history) => {
  if (!location.search) {
    return
  }

  const query = parse(location.search, { ignoreQueryPrefix: true })

  if (query.p === undefined) {
    return
  }

  const basePath = query.p || ''
  const hash = location.hash ? location.hash.substr(1) : ''
  const path = `${basePath}/${hash}`.replace(/\/{2,}/g, '/')
  const search = query.q ? `?${query.q}` : ''
  const route = `/cor/main/${search}#${path}`

  history.replaceState(null, null, route)
}

export default replaceQueryWithHash
