/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

/*
 * Don't add anything else to this file!! It will not be tested. Files that
 * are only required here will not be considered in apps client's code
 * coverage total. The root of this app should be considered app.js
 */
import '@babel/polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import './common/fix-axios'
import hotjar from './common/hotjar'
import replaceQueryWithHash from './common/replace-query-with-hash'
import App from './app'
import './styles.css'

hotjar()
replaceQueryWithHash(window.location, window.history)

ReactDOM.render(
  <App messages={window.messages || {}} />,
  document.getElementById('app')
)
