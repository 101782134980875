/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

/*
 * Don't add anything else to this file!! It will not be tested. Files that
 * are only required here will not be considered in apps client's code
 * coverage total. The root of this app should be considered app.js
 */

import { HashRouter, Redirect, Route, Switch } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import PropTypes from 'prop-types'
import React from 'react'
import { createModulePage } from './module-page'

const Apps = createModulePage('Kuali Apps', () =>
  import(/* webpackChunkName: 'apps-module', webpackPrefetch: true */ './modules/apps/app')
)

const Auth = createModulePage('Kuali Auth', () =>
  import(/* webpackChunkName: 'auth-module' */ './modules/auth/app')
)

const Groups = createModulePage('Kuali Groups', () =>
  import(/* webpackChunkName: 'groups-module' */ './modules/groups/app')
)

const Roles = createModulePage('Kuali Roles', () =>
  import(/* webpackChunkName: 'roles-module' */ './modules/roles')
)

const Users = createModulePage('Kuali Users', () =>
  import(/* webpackChunkName: 'users-module' */ './modules/users/app')
)

const App = ({ messages }) => (
  <IntlProvider locale='en' messages={messages}>
    <HashRouter>
      <Switch>
        <Redirect from='/' exact to='/apps' />
        <Route path='/apps' component={Apps} />
        <Route path='/auth' component={Auth} />
        <Route path='/groups' component={Groups} />
        <Route path='/roles' component={Roles} />
        <Route path='/users' component={Users} />
      </Switch>
    </HashRouter>
  </IntlProvider>
)

App.displayName = 'CorRouter'

App.propTypes = {
  messages: PropTypes.objectOf(PropTypes.string.isRequired)
}

export default App
