/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

import React, { Component } from 'react'
import { injectIntl, intlShape } from 'react-intl'

import msgs from './messages'
import styles from './styles'

class Preloader extends Component {
  static displayName = 'Preloader'

  static propTypes = {
    intl: intlShape.isRequired
  }

  constructor () {
    super()
    this.state = { height: 'auto' }
    this.setHeight = this.setHeight.bind(this)
  }

  setHeight (ref) {
    if (ref) {
      let windowHeight = window.innerHeight
      let elementTop = ref.getBoundingClientRect().top
      this.setState({ height: windowHeight - elementTop })
    }
  }

  render () {
    const { height } = this.state
    return (
      <div className={styles.wrapper} ref={this.setHeight} style={{ height }}>
        <div className={styles.panel}>
          <span className={styles.msg}>
            {this.props.intl.formatMessage(msgs.main)}
          </span>
          <img
            alt={this.props.intl.formatMessage(msgs.alt)}
            className={styles.img}
            src='/img/v1/loading_animation.svg'
          />
        </div>
      </div>
    )
  }
}

export default injectIntl(Preloader)
export const PurePreloader = Preloader
